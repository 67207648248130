import { useEffect, useRef, useState } from "react";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import InnerImageZoom from "react-inner-image-zoom";
import { useCallback, Fragment } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function PDF({ item, pageNumber, setLoading }) {
  useEffect(() => {
    setLoading(false);
  }, [item]);
  const ref = useRef();
  useEffect(() => {
    if (ref.current) ref.current.resetTransform();
    // console.log(ref.current);
  }, [pageNumber, ref]);
  return (
    <div className="pdf-wrapper">
      <TransformWrapper wheel={{ wheelDisabled: true }} ref={ref}>
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <Fragment>
            <div className="tools">
              <div onClick={() => zoomIn()} className="pdfzoom in">
                +
              </div>
              <div onClick={() => zoomOut()} className="pdfzoom out">
                -
              </div>
            </div>
            <TransformComponent>
              <img
                onClick={() => zoomOut()}
                src={item.folder[pageNumber].url}
                style={{ width: "100%", height: "100%" }}
              />
            </TransformComponent>
          </Fragment>
        )}
      </TransformWrapper>
    </div>
  );
}
