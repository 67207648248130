import { useEffect, useState } from "react";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
export default function Publication({ item, pageNumber, setLoading }) {
  useEffect(() => {
    setLoading(false);
  }, [item]);
  return (
    <div className="pdf-wrapper">
      <img
        alt={item.title}
        src={item.folder[pageNumber].big}
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          objectFit: "contain",
          touchAction: "none",
        }}
      />
    </div>
  );
}
