import ReactPlayer from "react-player/lazy";
const Iphonevideo = ({ item }) => (
  <>
    <div className="wrapper">
      <ReactPlayer
        playing
        loop={true}
        muted={true}
        url={item.url}
        className="player"
        width="100%"
        height="100%"
      />
    </div>
  </>
);

export default Iphonevideo;
