import { a, useSpring } from "@react-spring/web";
import { useEffect } from "react";
export default function Loading({ loading }) {
  const [spring, api] = useSpring(() => ({
    opacity: 0,
  }));
  useEffect(() => {
    api.start({
      opacity: loading ? 1 : 0,
    });
  }, [loading]);
  return (
    <a.div
      style={{
        position: "absolute",
        top: 32,
        left: 0,
        width: "100%",
        height: "calc(100% - 32px)",
        backgroundColor: "white",
        color: "rgb(100, 116, 139)",
        zIndex: 100,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        animation: "loading 6s infinite ease-in-out",
        fontSize: "4vw",
        pointerEvents: "none",
        ...spring,
      }}
    >
      Loading…
    </a.div>
  );
}
