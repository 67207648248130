import React, { useEffect, useState } from "react";
import { useSpring, a } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

const Slider = ({ set }) => {
  const [{ x, scale }, api] = useSpring(() => ({
    x: 0,
    scale: 1,
  }));

  const bind = useDrag(
    ({ active, offset: [x] }) => {
      api.start({
        x: x,
        scale: active ? 1.4 : 1,
      });
      set(x * 2);
    },
    { bounds: { left: 0, right: 50 } }
  );

  return (
    <>
      <div
        {...bind()}
        style={{
          position: "relative",
          display: "inline-block",
          margin: 4,
          userSelect: "none",
          width: 60,
          height: 5,
          backgroundColor: "#eeeeee",
        }}
      >
        <a.div
          style={{
            cursor: "pointer",
            position: "absolute",
            backgroundColor: "#C084FC",
            width: 12,
            height: 5,
            x,
            scale,
          }}
        />
      </div>
    </>
  );
};
export default function Sliders() {
  const [phon, setPhon] = useState(0);
  const [phtw, setPhtw] = useState(0);
  const [phth, setPhth] = useState(0);
  useEffect(() => {
    // console.log(phon, phtw, phth);
    document.body.style.fontVariationSettings = `"phon" ${phon}, "phtw" ${phtw}, "phth" ${phth}`;
  }, [phon, phtw, phth]);
  return (
    <div style={{ display: "grid", gridTemplateColumns: "80px 80px 80px" }}>
      <Slider set={setPhon} />
      <Slider set={setPhtw} />
      <Slider set={setPhth} />
    </div>
  );
}
