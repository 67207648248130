import { useState, useEffect, useMemo, useRef } from "react";
import { a, useTransition } from "@react-spring/web";
import useMeasure from "react-use-measure";
import { atom, useAtom } from "jotai";
import shuffle from "lodash.shuffle";
import { Link } from "wouter";
import useStore from "../zustand";

import { useInView } from "react-intersection-observer";
import Webgl from "./Webgl";
import Iphonevideo from "./Iphonevideo";
import PDF from "./PDF";
import Text from "./Text";
import Video from "./Video";
import Publication from "./Publication";

import Noitems from "./Noitems";
import Dataprivacy from "./Dataprivacy";
import { useGesture } from "@use-gesture/react";

export const currentHoverAtom = atom();
export const columnsAtom = atom(3);
export const buttonAtom = atom(false);
export const shufflerAtom = atom(false);
function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num;
}
function Components({ item, /*style,*/ setCurrentHover, ...props }) {
  const [open, setOpen] = useAtom(buttonAtom);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const aref = useRef();
  const [hidden, setHidden] = useState(false);
  return (
    <div
      title={props.href}
      style={{
        transform: `translate(${item.x}px, ${item.y}px)`,
        width: item.width,
        height: item.height,
      }}
      onMouseOver={() => setCurrentHover(item)}
      onClick={() => {
        setOpen(!open);
      }}
      ref={aref}
    >
      <div
        ref={ref}
        style={{
          height: "100%",
          opacity: inView ? 1 : 0,
          // backgroundColor: "white",
        }}
        className="opacity-transition"
      >
        {!hidden && (
          <div style={{ height: "100%" }}>
            {inView && item.type === "image" && (
              <img
                src={item.small}
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: "0px",
                }}
                alt={item.id}
                className="loading"
              />
            )}
            {inView && item.type === "svg" && <PDF item={item} />}
            {inView && item.type === "publication" && (
              <Publication item={item} />
            )}
            {item.type === "text" && <Text item={item} />}
            {inView && item.type === "webgl" && <Webgl item={item} />}
            {inView && item.type === "iphonevideo" && (
              <Iphonevideo item={item} />
            )}
            {inView && item.type === "video" && <Video item={item} />}
          </div>
        )}
      </div>
    </div>
  );
}

function MasonryItem({ /* style,*/ item, width, itemwidth, itemheight }) {
  const [, setCurrentHover] = useAtom(currentHoverAtom);
  return <Components item={item} setCurrentHover={setCurrentHover} />;
}

//   width > 700 ? (<Link href={`/p/${item.id}`}>
//     <Components
//       item={item}
//       /*style={style}*/ setCurrentHover={setCurrentHover}
//     />
//   </Link>
// ) : (
//   <Components
//     item={item}
//     /*style={style}*/ setCurrentHover={setCurrentHover}
//   />

export default function Masonry({ data }) {
  const [refWidth, { width }] = useMeasure();
  useEffect(() => {
    const handler = (e) => e.preventDefault();
    document.addEventListener("gesturestart", handler);
    document.addEventListener("gesturechange", handler);
    document.addEventListener("gestureend", handler);
    return () => {
      document.removeEventListener("gesturestart", handler);
      document.removeEventListener("gesturechange", handler);
      document.removeEventListener("gestureend", handler);
    };
  }, []);
  const [liveData, setLiveData] = useState();
  const [shuffler, setShuffler] = useAtom(shufflerAtom);

  useEffect(() => {
    setLiveData(data);
    setLiveData(shuffle);
  }, [data]);
  useEffect(() => {
    setLiveData(shuffle);
    setShuffler(false);
  }, [shuffler]);
  const [columns, setColumns] = useAtom(columnsAtom);
  const [heights, gridItems] = useMemo(() => {
    let heights = new Array(columns).fill(0);
    const gridItems = liveData?.map((item, i) => {
      const column = heights.indexOf(Math.min(...heights));
      const aspect = item.height / item.width;
      const x = (width / columns) * column;
      const customc =
        item.type !== "text" && item.type !== "iphonevideo"
          ? (width / columns) * aspect
          : item.type === "text" && item.type !== "iphonevideo"
          ? clamp(120 * columns - width / columns, 200, 300)
          : (width / columns) * (item.orientation === "landscape" ? 0.56 : 1.7);

      const y = (heights[column] += customc) - customc;
      const height = customc;
      return {
        ...item,
        x,
        y,
        width: width / columns,
        height,
      };
    });
    return [heights, gridItems];
  }, [columns, liveData, width]);

  // const transitions = useTransition(gridItems, {
  //   key: (item) => item.id,
  //   from: ({ x, y, width, height }) => ({
  //     x,
  //     y,
  //     width,
  //     height,
  //     opacity: 0,
  //     // scale: 0,
  //   }),
  //   enter: ({ x, y, width, height }) => ({
  //     x,
  //     y,
  //     width,
  //     height,
  //     opacity: 1,
  //     // scale: 1,
  //   }),
  //   leave: {
  //     opacity: 0,
  //     // scale: 0,
  //   },
  //   update: ({ x, y, width, height }) => ({
  //     x,
  //     y,
  //     width,
  //     height,
  //   }),
  //   trail: 12,
  //   immediate: true,
  // });
  // console.log(gridItems);
  useEffect(() => {
    if (gridItems && gridItems.length === 0) {
      useStore.setState({ nocontent: true });
    } else {
      useStore.setState({ nocontent: false });
    }
  }, [gridItems]);

  const [, setOpen] = useAtom(buttonAtom);
  const ref = useRef();
  useGesture(
    {
      onPinch: ({ offset: [s] }) => {
        if (s > 1.5 && width < 700) {
          setColumns(Math.floor(s));
        }
      },
    },
    {
      target: ref,
      pinch: { scaleBounds: { min: 1, max: 4 }, rubberband: true },
    }
  );

  // const [introTimeout, setIntroTimeout] = useState(false);
  // useEffect(() => {
  //   const timer = setTimeout(() => setIntroTimeout(true), 1000);
  //   return () => clearTimeout(timer);
  // }, []);
  return (
    <>
      <div ref={refWidth} />
      <div
        ref={ref}
        className="list"
        style={{ height: Math.max(...heights), minHeight: "100vh" }}
        // onClick={(e) => setOpen(false)}
      >
        {/* {transitions((style, item) => {
          return (
            introTimeout && (
              <MasonryItem style={style} item={item} width={width} />
            )
          );
        })} */}
        {gridItems &&
          gridItems.map((item, i) => {
            return <MasonryItem item={item} width={width} />;
          })}

        {gridItems && gridItems.length === 0 && <Noitems />}
        <Dataprivacy />
      </div>
    </>
  );
}
