import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import { useRef, useState } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { Html } from "@react-three/drei";

const Model = ({ item }) => {
  //   const gltf = useLoader(GLTFLoader, item.small);
  const gltf = useLoader(
    ((item.extension === "glb" || item.extension === "gltf") && GLTFLoader) ||
      (item.extension === "ply" && PLYLoader),
    item.big
  );
  const ref = useRef();
  useFrame(({ clock }) => {
    // ref.current.rotation.y = clock.getElapsedTime();
  });
  let positions, colors;
  if (item.extension === "ply") {
    positions = gltf.attributes.position.array;
    colors = gltf.attributes.color.array;
  }
  const [pointSize, setPointSize] = useState(0.02);
  return item.extension === "gltf" || item.extension === "glb" ? (
    <>
      <ambientLight />
      <primitive ref={ref} object={gltf.scene} />
    </>
  ) : (
    <>
      <Html center>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            width: 58,
            userSelect: "none",
            cursor: "pointer",
          }}
        >
          <div
            onClick={() => {
              if (pointSize >= 0.005) setPointSize(pointSize * 0.85);
            }}
            style={{
              width: 24,
              height: 24,
              backgroundColor: "#EF4444",
              textAlign: "center",
            }}
          >
            -
          </div>
          <div
            onClick={() => {
              if (pointSize <= 2) setPointSize(pointSize * 1.15);
            }}
            style={{
              width: 24,
              height: 24,
              backgroundColor: "#22C55E",
              textAlign: "center",
            }}
          >
            +
          </div>
        </div>
      </Html>
      <points ref={ref}>
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attach="attributes-position"
            count={positions.length / 3}
            array={positions}
            itemSize={3}
          />
          <bufferAttribute
            attach="attributes-color"
            count={colors.length / 3}
            array={colors}
            itemSize={3}
          />
        </bufferGeometry>
        <pointsMaterial
          attach="material"
          vertexColors
          size={pointSize}
          sizeAttenuation={true}
        />
      </points>
    </>
  );
};
export default Model;
