import ReactPlayer from "react-player/lazy";
const Webgl = ({ item }) => (
  <>
    {item.previewtype === "image" ? (
      <img src={item.small} style={{ width: "100%", height: "100%" }} />
    ) : (
      <div className="wrapper">
        <ReactPlayer
          playing
          loop={true}
          muted={true}
          url={item.small}
          className="player"
          width="100%"
          height="100%"
        />
      </div>
    )}
  </>
);

export default Webgl;
