import create from "zustand";
import produce from "immer";

const useStore = create((set) => ({
  globalPage: 0,
  columns: 4,
  contact: null,
  contacttwn: null,
  // opencontact: false,
  data: [],

  //
  titletags: [],
  projecttags: [],

  //
  selectedtitles: [],
  selectedtags: [],
  opencontact: false,
  // active project
  currently: null,
  nocontent: false,
}));
export default useStore;
