import { useEffect } from "react";
export default function Video({ item, setLoading }) {
  useEffect(() => {
    setLoading(false);
  }, [item]);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "black",
      }}
    >
      {/* <ReactPlayer url={item.url} width="100%" height="100%" /> */}

      <iframe
        src={`https://www.youtube.com/embed/${item.url}?autoplay=1&mute=1`}
        width="100%"
        height="100%"
        frameBorder="0"
        className="videomobile"
      ></iframe>
    </div>
  );
}
