import { useEffect } from "react";
import { a, useSpring } from "@react-spring/web";
import useWindowDimensions from "./useWindowDimensions";
export default function Intro() {
  const [spring, api] = useSpring(() => ({
    opacity: 1,
  }));
  useEffect(() => {
    setTimeout(() => {
      api.start({
        opacity: 0,
      });
    }, 6000);
  }, []);
  return (
    <a.div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        backgroundColor: "white",
        width: "100vw",
        height: "100vh",
        zIndex: 10,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "7vw",
        wordWrap: "break-word",
        wordBreak: "break-all",
        textAlign: "center",
        color: "#4a4a4a",
        ...spring,
      }}
    >
      <div className="intro">Unprofessional Studio</div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          fontSize: 16,
          margin: 12,
        }}
      >
        Beta v.1.2
      </div>
    </a.div>
  );
}
