import useStore from "../zustand";
const Noitems = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        fontSize: "5vw",
        display: "flex",
        overflow: "hidden",
        textAlign: "center",

        gap: 12,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      className="nomatch"
      onClick={() => {
        useStore.setState({
          selectedtitles: [],
          selectedtags: [],
        });
      }}
    >
      <div style={{ perspective: 400 }}>
        <div
          style={{
            width: "100vw",
          }}
        >
          <div>No match! Click to reset tags</div>
          <div>No match! Click to reset tags</div>
          <div>No match! Click to reset tags</div>
        </div>
      </div>
    </div>
  );
};
export default Noitems;
