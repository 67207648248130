import ReactPlayer from "react-player/lazy";
export default function Iphonevideo({ item, setLoading }) {
  // console.log(item);
  return (
    <div className="player-wrapper">
      <ReactPlayer
        onReady={() => {
          setLoading(false);
        }}
        playing={true}
        loop={true}
        muted={true}
        url={item.url}
        className="react-playercontent"
        width="100%"
        height="100%"
      />
    </div>
  );
}
