import useScrollTo from "react-spring-scroll-to-hook";

import useStore from "../zustand";
import produce from "immer";
export default function TitleTag({
  currentHover,
  currenttitletag,
  currentprojecttag,
  api,
}) {
  const { scrollTo } = useScrollTo();
  return (
    currentHover[0] && (
      <div
        style={{
          display: "inline-block",
          color: currenttitletag.includes(currentHover[0].project)
            ? "#3b82f6"
            : "#475569",
          border: currenttitletag.includes(currentHover[0].project)
            ? "1px solid #3b82f6"
            : "1px solid #475569",
          margin: 2,
          padding: "4px 6px",
          lineHeight: "14px",
          borderRadius: 14,
          cursor: "pointer",
          "&:hover": {
            color: currentprojecttag.includes(currentHover[0].project)
              ? "#3b82f6"
              : "#2f3845",
          },
        }}
        onClick={() => {
          const index = currenttitletag.findIndex(
            (prod) => prod === currentHover[0].project
          );
          useStore.setState(
            produce((state) => {
              if (state.selectedtitles.includes(currentHover[0].project)) {
                state.selectedtitles.splice(index, 1);
              } else {
                state.selectedtitles.push(currentHover[0].project);
              }
            })
          );
          scrollTo();
          api.start({
            y: 16,
          });
        }}
      >
        {currentHover[0] && currentHover[0].project}
      </div>
    )
  );
}
