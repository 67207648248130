import { useState, useEffect, useRef } from "react";
import toImg from "react-svg-to-image";
import { Canvg } from "canvg";

const PDF = ({ item }) => {
  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    if (item.folder.length === 1) {
      setPageNumber(0);
      return;
    }
    const interval = setInterval(() => {
      if (pageNumber < item.folder.length - 1) {
        setPageNumber(pageNumber + 1);
      } else {
        setPageNumber(0);
      }
    }, Math.floor(Math.random() * 600) + 1600);
    return () => {
      clearInterval(interval);
    };
  }, [item, pageNumber]);
  return (
    <>
      {item.folder.map((folder, i) => {
        return (
          <img
            src={folder.url}
            alt={folder.id}
            width={item.width}
            height={item.height}
            style={{
              backgroundColor: "white",
              display: pageNumber === i ? "block" : "none",
            }}
          />
        );
      })}
    </>
  );
};

export default PDF;
