import { useEffect } from "react";

export default function Text({ handlers, item, setLoading }) {
  useEffect(() => {
    setLoading(false);
  }, [item]);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        maxWidth: 600,
        overflow: "scroll",
        padding: 12,
        fontVariationSettings: '"phon" 0, "phtw" 0, "phth" 0',
        touchAction: "pan-y",
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: item.textcontent }} />
    </div>
  );
}
