import ReactPlayer from "react-player/lazy";

const Video = ({ item }) => {
  return (
    <div className="wrapper" style={{ pointerEvents: "none" }}>
      {/* <iframe
        src={`https://www.youtube.com/embed/${item.url}?controls=0&amp;start=10&amp;autoplay=1&amp;playlist=${item.url}&amp;mute=1&amp;loop=1`}
        width="100%"
        height="100%"
        frameBorder="0"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe> */}

      <ReactPlayer
        playing
        loop={true}
        muted={true}
        url={item.preview}
        className="player"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default Video;
{
  /* <iframe width="560" height="315" src="https://www.youtube.com/embed/hC-LpzXSqKQ?controls=0&amp;start=10" ></iframe> */
}
