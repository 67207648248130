export default function Image({ bear, onLoadHandler }) {
  return (
    <img
      draggable={false}
      alt={bear.title}
      src={bear.big}
      onLoad={onLoadHandler}
      style={{
        position: "relative",
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
        touchAction: "none",
      }}
    />
  );
}
