import { useState, useEffect } from "react";

const Publication = ({ item }) => {
  const numPages = item.folder.length;
  const [pageNumber, setPageNumber] = useState(0);
  useEffect(() => {
    if (numPages === 1) return;
    const interval = setInterval(() => {
      if (pageNumber < numPages - 1) {
        setPageNumber(pageNumber + 1);
      } else {
        setPageNumber(0);
      }
    }, Math.floor(Math.random() * 600) + 1600);
    return () => {
      clearInterval(interval);
    };
  }, [numPages, pageNumber]);
  return (
    <>
      <img
        src={item.folder[pageNumber].small}
        alt={item.folder[pageNumber].small}
        style={{ width: "100%", height: "100%", backgroundColor: "white" }}
      />
    </>
  );
};

export default Publication;
