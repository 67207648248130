import { useState, useEffect } from "react";
export default function Dataprivacy() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState();
  useEffect(() => {
    const url = "https://unprofessional.studio/backend/dataprivacy.json";

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error("error", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
    }
  });
  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          fontSize: 9,
          padding: 2,
          color: "grey",
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        Data Privacy / Datenschutz
      </div>
      {open && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            overflow: "scroll",
            top: 0,
            left: 0,
            position: "fixed",
            backgroundColor: "#f1f5f9",
          }}
          className="dataprivacy"
        >
          <div
            onClick={() => setOpen(!open)}
            style={{
              cursor: "pointer",
              margin: 12,
              border: "1px solid black",
              display: "inline-block",
              width: 24,
              height: 24,
              textAlign: "center",
            }}
          >
            x
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: data }}
            style={{ margin: 12 }}
          ></div>
        </div>
      )}
    </>
  );
}
