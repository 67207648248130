import useStore from "./zustand";
import { a, useSpring } from "@react-spring/web";
import { useEffect, useState } from "react";
export default function Contact() {
  const contact = useStore((s) => s.contact);
  const contacttwn = useStore((s) => s.contacttwn);
  var offset = new Date().getTimezoneOffset();
  const [china, setChina] = useState(false);
  useEffect(() => {
    if (offset / 60 === -8) {
      setChina(true);
    }
  }, [offset]);

  const opencontact = useStore((s) => s.opencontact);

  // console.log(contact);
  const [spring, api] = useSpring(() => ({
    opacity: 0,
  }));
  useEffect(() => {
    if (opencontact) {
      document.body.style.overflow = "none";
    } else {
      document.body.style.overflow = "inherit";
    }
    api.start({
      opacity: opencontact ? 1 : 0,
    });
  }, [opencontact]);
  return (
    <a.div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        zIndex: 100,
        padding: 12,
        pointerEvents: opencontact ? "inherit" : "none",
        overflow: "scroll",
        ...spring,
      }}
      className="contact"
    >
      <div
        onClick={() => {
          useStore.setState({ opencontact: false });
        }}
        style={{ position: "fixed", width: 24, height: 24, cursor: "pointer" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
          }}
        >
          <g id="close">
            <path
              id="x"
              d="M18.717 6.697l-1.414-1.414-5.303 5.303-5.303-5.303-1.414 1.414 5.303 5.303-5.303 5.303 1.414 1.414 5.303-5.303 5.303 5.303 1.414-1.414-5.303-5.303z"
            />
          </g>
        </svg>
      </div>
      <div dangerouslySetInnerHTML={{ __html: china ? contacttwn : contact }} />
    </a.div>
  );
}
