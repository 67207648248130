import fullScreen from "./fullScreen.svg";
const MyButton = (props) => (
  <div title={props.href}>
    <div onClick={props.onClick} style={{ margin: 6, cursor: "pointer" }}>
      <>
        <img src={fullScreen} style={{ margin: 6 }} />
        <div
          style={{
            display: "inline-block",
            verticalAlign: "top",
            margin: 2,
            fontSize: 24,
          }}
          className="buttonopen"
        >
          Open
        </div>
      </>
    </div>
  </div>
);
export default MyButton;
